.btn {
  text-transform: none;
  font-size: 16px;
  border-radius: 10px !important;
  font-weight: 600;

  &.btn-sm {
    padding: 4px 8px;
    margin-bottom: 0;
    line-height: 20px;
    font-weight: 800;
    min-height: 0;
    font-size: 14px;
  }

  &.btn-xsm {
    min-height: 0 !important;
    height: 22px !important;
    padding: 0 6px !important;
    line-height: 20px !important;
    text-transform: none !important;
    font-weight: 600;
  }

  &.active .fa.check-icon {
    line-height: 17px;
    color: theme-color("secondary");
    padding: 7px 10px;
    border-radius: 100%;
    background: white;
    font-size: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  }

  &:focus,
  .focus {
    outline: 0;
    box-shadow: none !important;
  }
}

.btn-hoverable .hidden-hover {
  display: none;
}
.btn-hoverable:hover .hidden-hover {
  display: inline-block;
}

.btn-group button {
  min-height: 50px;
  margin-right: 0;
  margin-bottom: 10px;
  // background: white;
  padding: 8px 15px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: none;
  flex: 1;
}

.hidden {
  height: 0 !important;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}
.btn-success {
  color: white;

  &:hover {
    color: white;
  }
}
.btn-outline-grey:hover {
  color: #fff;
}
.arrow-box-up:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: $secondary;
  border-width: 10px;
}
.btn-grey {
  color: white;
}
.btn-info {
  color: white;
}

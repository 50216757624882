#topsidemenu {
  .nav-link {
    position: relative;
    line-height: 30px;
    font-weight: 600;
    border-radius: 0px !important;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: -3px;
    //   left: 0;
    //   height: 2px;
    //   background: $default;
    //   width: 100%;
    //   transform: scale(0);
    //   transition: 400ms;
    //   box-shadow: 0px 3px 16px 1px $secondary;
    // }

    &:hover {
      background: #04173e;
    }
    &[data-attr="active"] {
      color: white !important;
      background: #04173e;
    }

    &:hover,
    &[data-attr="active"] {
      &::after {
        transform: scale(1);
      }
    }
  }
  .bottom-menubar {
    //background: #2e4981;
    .nav-link {
      border-left: 1px solid #2e4981;
    }
  }
}

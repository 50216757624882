$body-bg: #efefef;
$body-color: #0e2b67;
$font-family-sans-serif: "Nunito", sans-serif;
$border-radius: 5px;

$primary: #dc142d;
$secondary: #0e2b67;
$success: #028d33;
$info: #009eff;
$warning: yellow;
$danger: #dc142d;
$light: #ffffff;
$dark: black;
$grey: grey;
$trans: rgba(255, 255, 255, 0.01);
$default: #ededed;
$input: #ededed;
$verylight: #f7f7f7;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$custom-colors: (
  "default": $default,
  "grey": $grey,
  "input": $input,
  "verylight": $verylight,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800&display=swap");

@import "./topmenu.scss";
@import "./topsidemenu.scss";
@import "./inputs.scss";
@import "./buttons.scss";

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grecaptcha-badge {
  opacity: 0;
}

html  {
  background: white;
}
html,
body,
#root,
.wrapper {
  min-height: 100vh;
  //background: $body;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  scroll-behavior: smooth;
  color: #b7b7b7;
  transition: 1s;
}
.wrapper {
  padding-top: 120px;
  padding-bottom: 100px;
}
:focus {
  outline: none;
}
.tab-item:hover {
  background-color: $verylight;
}

#topmenu {
  .nav-link {
    position: relative;
    line-height: 30px;
    font-weight: 600;

    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      height: 2px;
      background: $default;
      width: 100%;
      transform: scale(0);
      transition: 400ms;
      box-shadow: 0px 3px 16px 1px $secondary;
    }
    &[data-attr="active"] {
      color: white !important;
    }

    &:hover,
    &[data-attr="active"] {
      &::after {
        transform: scale(1);
      }
    }
  }
}
